//  Alert
.alert {
  border-radius: 5px;
}

.alert-primary {
  color: #000;
  background-color: $primary-color !important;
}

.alert-secondary {
  color: #000;
  background-color: $secondary-color !important;
}

.alert-info {
  color: #000;
  background-color: $info-color !important;
}

.alert-success {
  color: #000;
  background-color: $primary-light-3 !important;
}

.alert-warning {
  color: #000;
  background-color: $warning-color !important;
}

.alert-danger {
  color: #000;
  background-color: $danger-color !important;
}
