.GaugeComponent{
  //background-color: red !important;
}

.highcharts-button-symbol {
  stroke: black !important;
  stroke-width: 2 !important;
}

text.highcharts-title  {
  color:#000 !important;
  fill: black !important;
  font-family: Arial;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 21px;
}

.highcharts-reset-zoom {
  display: none;
}

.highcharts-menu {
  box-shadow: #ddd 3px 3px 5px !important;
  
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}