// Print
@media print {
  @page {
    size: landscape;
    margin: 0mm;
  }

  html, body {
    background-color: #ffffff;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  table th, .table th, .table thead th {
    background-color: $primary-color;
  }

  .c-app {
    background-color: #ffffff;
  }

  .c-sidebar {
    display: none;
  }

  .c-wrapper {
    margin-left: 0px;

    .c-header {
      display: none;
    }

    .c-main {
      padding: 0px;

      .container-fluid {
        padding: 0px;
      }
    }
  }

  .ag-side-bar {
    display: none !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
