//  Tags
.CrossArea{
  &::after {
    content: '';
    display: block;
  }
  .square {
    border: 1px solid #E9E9E9;
    border-radius: 0px;
    padding: 5px;
    min-height: 30px;
  }
  .left {
    float: left;
    .tag {
      position: relative;
      margin: 3px;
      font-size: 13px;
      border: 1px dashed #cccccc;
      border-radius: 0px;
      padding: 0 8px;
      line-height: 30px;
      color: #666666;
      background: rgba(255, 255, 255, 0.7);
    }
  }
  .right {
    float: right;
    .tag {
      position: relative;
      margin: 3px;
      font-size: 13px;
      border: 1px dashed $primary-color;
      border-radius: 0px;
      padding: 0 8px;
      line-height: 30px;
      color: #666666;
      background: rgba(255, 255, 255, 0.7);
    }
    .delete {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      user-drag: none; 
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
}
.square > label {
  width: 100%
}

.CrossArea .left .tag, .CrossArea .right .tag {
  border-radius: 4px;
  border-color: $primary-light-1;
  font-size: 12px;
  color: black; 
}

.DraggableTags {
  width: auto;
  min-height: 40px;
  padding: 0px;
  border: 1px solid $lines-input-color;
}

.DraggableTags-tag {
  color: initial !important;
  float: left;
}




.actions-entry {
    margin: 4px;
    border: 1px solid $lines-input-color;
    border-radius: 5px;
    padding: 10px;
    background-color: $primary-light-4;
    max-width: 340px;
    //background-color: red;

    .float-right.btn.btn-link.btn-sm {
      //background-color: yellow;
      margin-top: -8px !important; 
    }

    button.action-entry-delete.float-right.btn.btn-sm {
      /*
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABRUlEQVRoge2ZQWrDMBBFHz1JFz1JCiX72l04zdmz6BlKoQmki2agNZKxJc2MIvQguzD/P0lOHAc6nU6nNZ6AwTF/uHXI4hE4ARfgmDssgTfgDHyQISMS19vLWkYkJD9ZZuS3/JX/MociNZc5RLLH1IHzVbHYGbVMSxn1LAsZswXTDDI/whqBHtdh8WA3iZIF3CVKFKlGIqdQdRLClmLVSghrClYvISwVvRsJIXaz53XzmUVo9e9mJ+bEZNQkHjSGtkITR2uigYu9iY/fkXDR9z/vqV5mjYRQrcwWCaE6mRQJoRqZIVJkjYTgLlNCQnCTiUnkfCeYy2hICGYymhKCuswAfAcCNG4x1GReCUtMuYMXKC4TOk5nMh7tbyD2SzNpAV+ArxKDEpnvzCfwnDpMZKwlBJHJkhD2+P4ZOgE7x/xOp9NR4AcMT3Cx1TO+AwAAAABJRU5ErkJggg==");
      background-position: center; 
      background-repeat: no-repeat; 
      background-size: cover;
      width: 8px;
      height: 8px;
      padding: 8px;
      */
 
      padding: 0px 8px 0px 0px;
      height: 20px;
      //background-color: $primary-light-4;

  }
} 

