
.current-machine-of {
  background-color: transparent;
  padding: 0px;
  
    .bg-success {
      background-color: $primary-color !important;
      border-radius: 0px 5px 5px 0px!important;
    } 
  
    .text, .text-value {
      color: black;
      text-transform: uppercase;
      font-size: 11px;
      padding: 0px 20px !important;
      margin: 0px;
    }
   
    .text-value {
      color: black;
      font-size: 12px;
    }
  
    .c-icon {
      width: 20px !important;
      height: 20px !important;
      padding: 0px !important;
      margin: 0px !important;
    }
  }
  
  
  