.modal-content {
  // generic infocard
  .Create__Infocard--Container,
  .Chart__Module--Component {
    .nav-tabs {
      margin-left: 15px;
    }

    .card-body {
      padding: 20px 5px;
    }

    .form-group {
      margin-bottom: 30px;
      label {
        margin-left: 10px;
      }
    }

    .Create__Interfaces--Form {
      margin-bottom: 30px;
    }

    .Map__Results--Column button.ant-btn.ant-btn-primary,
    button#Add--button {
      margin-top: 28px;
      height: 40px;
    }

    .Cancel--button {
      background-color: $cancel-color;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      height: 40px;
      border-radius: 5px;
      margin-left: 20%;
      margin-top: 28px;
    }

    // ant table
    .ant-table-tbody {
      button {
        background-color: transparent;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        margin-right: 3px;

        &:hover {
          background-color: $primary-color;
          color: $text-color;
        }
      }
      button.editing {
        background-color: $primary-color;
        color: $text-color;
      }
    }

    .ant-table-thead > tr > th {
      background-color: $primary-color;
      color: $text-color;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      padding: 0px 0px 0px 15px;
      height: 40px;

      &:first-child {
        border-radius: 5px 0px 0px 0px;
      }
      &:last-child {
        border-radius: 0px 5px 0px 0px;
      }
    }

    .ant-table-tbody > tr > td {
      padding: 5px 0px 5px 15px;
      border-top: 1px solid $lines-input-color;
    }
  }

  // new table component
  .newtable-component {
    .nav-tabs {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 15px;
    }

    .tab-content {
      padding: 15px;
    }
  }

  // table component modal
  .table-component {
    .nav-tabs {
      margin-left: 15px;
      margin-top: 15px;
    }
    .tab-content {
      padding: 20px;
    }
  }
}

// ant modal
.ant-modal-content {
  .Each__Actions--Row {
    .btn {
    }
    .c-icon {
    }

    .Modal_Btn {
      background-color: transparent;
      //border: 2px solid $primary-color;
      background-color: $primary-color;
      font-size: 11px;
      //color: $primary-color;
      color: $text-color;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 2px;
      height: 34px;
      margin-right: 13px;
    }

    .Modal_Cancel {
      background-color: $cancel-color;
      color: black;
      float: right;
      width: 60px;
      height: 34px;
    }
  }

  .Each__Toolbar--Row {
    width: 95%;
    margin-top: 20px;
    display: flex;

    .Modal_Btn_Icon {
      background-color: $primary-color;
      color: $text-color;
      height: 34px;
      width: 34px;
      padding: 2px;
      margin-right: 13px;
    }

    .Modal_Btn_Icon.disabled {
      background-color: transparent;
      opacity: 1;
      color: $color-disabled;
      background-color: #E6E6E6;
      // background-color: $background-disabled;
    }

    .Modal_Cancel {
      background-color: $cancel-color;
      color: black;
      width: 60px;
      height: 34px;
    }

    .toolbar-action {
      display: inline-block;
    }

    .toolbar-action:last-child {
      margin-left: auto;
    }
  }

  .ant-modal-title {
    font-weight: bold;
  }

  .ant-checkbox-wrapper {
    margin-right: 20px;
  }

  input {
    margin-right: 20px;
  }

  .ant-modal-footer {
    padding: 12px;
    border-radius: 0px 0px 5px 5px;

    .ant-btn {
      background-color: $cancel-color;

      &:hover {
        background-color: $cancel-hover;
        color: black;
      }
    }

    .ant-btn-primary {
      margin-top: 0px;
      background-color: $primary-color;
      &:hover {
        background-color: $primary-color-hover;
        color: $text-color;
      }
    }

    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 15px;
    }
  }
}

// .modal-content {

//   .DynamicChart__Module--Component , .Chart__Module--Component {

//     .nav-tabs {
//         margin-left: 15px;
//     }

//     .Actions--form {
//         .Create__Edit--button:last-child {
//             // width: 70px;
//             height: 40px;
//             margin-top: 19px;
//             background-color: $primary-color;
//             color: $text-color;
//             margin-left: 20px;
//             &:hover {
//                 background-color: $primary-color-hover;
//                 color: $text-color;
//             }
//             &:disabled {
//                 cursor: not-allowed;
//             }
//         }

//         button.Cancel__Button.btn.btn-secondary.btn-sm{
//             background-color: $cancel-color;
//             margin-top: 18px;
//             color: black;
//             // width: 70px;
//             //margin-top: 38px;
//             &:hover {
//                 background-color: $cancel-hover;
//                 color: blac
//             }
//         }
//     }

//     .groupBy__Row td > input {
//         color: black !important;
//     }

//     .Select__control {
//             border-radius: 0px;
//             height: 50px;
//             .Select__placeholder, .Select__single-value {
//                 margin-left: 3px;
//                 //color: black;

//             }
//             .Select__value-container {
//                 padding-left: 20px;
//                 height: 50px;
//                 padding-top: 0;
//                 position: relative;
//                 .Select__input {
//                     margin: 0;
//                     height: 40px;
//                     input {
//                         height: 40px;
//                     }
//                 }
//                 .css-b8ldur-Input {
//                     height: 100%;
//                     margin-top: 0;
//                     margin-bottom: 0;
//                     top: 0;
//                     position: absolute;
//                     background-color: transparent;
//                 }
//             }
//             &--is-focused {
//                 border-color: inherit;
//                 box-shadow: inherit;
//             }
//         }

//         .ant-table-wrapper {
//             width: -webkit-fill-available;
//             tr {
//                 height: 40px;
//             }
//             thead .ant-table-cell:last-child {
//                 padding-left: 10px;
//             }
//             .ant-table table {
//                 width: -webkit-fill-available;
//             }
//             .Actions__Table--Row {
//                 height: 40px;
//             }
//         }

//     .DynamicChart__Module--Component table {
//         margin: 0px;
//     }

//     table {
//         width: -webkit-fill-available;
//         margin: 10px 10px;

//         th {
//             background-color: $primary-light-4;
//             height: 40px;
//         }
//         td {
//             font-size: 12px;
//             height: 40px;
//             background-color: $grey6-color;
//         }
//         tbody > tr > td:last-child {
//             display: flex;
//             justify-content: flex-start;
//             align-items: center;
//             padding-left: 0px;
//         }
//         button {
//             background-color: transparent;
//             padding: 10px;
//             border: none;
//             height: 35px;
//             width: 35px;
//             border-radius: 5px;

//             &:focus {
//                 outline: none;
//             }
//             .c-icon {
//                 width: 20px;
//                 height: 20px;
//                 margin-top: -5px;
//             }
//             &:hover {
//                 background-color: $primary-color;
//                 color: $text-color;
//             }
//             &.editing {
//                 background-color: $primary-color;
//             }
//             svg {
//                 margin: 0;
//             }
//         }
//         .Editing {
//             background-color: $white1-color;
//         }
//     }

//     input#label, input#label__axis {
//         padding-left: 15px;
//         &:required {
//             // background-image: url('./../../assets/icons/exclamation-mark.svg');
//             border-color: #E2574C;
//             background-repeat: no-repeat;
//             background-size: 20px;
//             background-position: 95% 50%;
//             padding-right: 40px;
//             font-size: 12px;
//         }
//     }
//     .nav-link {
//         font-weight: bold;
//         color: #B5B5B5;
//         &.active {
//             color: black;
//         }
//     }
//     input, .Select__value-container {
//         padding-left: 15px;
//     }
//     .form-group {
//         position: relative;
//         .text-muted {
//             color: #E2574C !important;
//             position: absolute;
//             right: 0;
//             display: none;
//         }
//     }
//     .form-group > div {
//         .Select__control {
//             border-radius: 5px;
//             height: 40px;
//             align-items: center;
//             .Select__placeholder, .Select__single-value {
//                 margin-left: 3px;
//                 top: 50%;
//             }
//             .Select__value-container {
//                 //height: 100%;
//                 padding-left: 20px;
//                 height: 40px;
//                 padding-top: 0;
//                 font-size: 12px;
//                 position: relative;
//                 .Select__input {
//                     margin: 0;
//                     height: 40px;
//                     input {
//                         height: 40px;
//                     }
//                 }
//                 .css-b8ldur-Input {
//                     height: 50%;
//                     margin-top: 0;
//                     margin-bottom: 0;
//                     top: 0;
//                     font-size: 12px;
//                     position: absolute;
//                     background-color: transparent;
//                 //   background-color: red;
//                 }
//             }
//             &--is-focused {
//                 border-color: inherit;
//                 box-shadow: inherit;
//             }
//         }
//         .Select__menu {
//             border-radius: 0px;
//             padding: 0;
//             top: 50px;
//             margin: 0;
//             //height: fit-content;
//         }
//         .css-b8ldur-Input {
//             left: 16px;
//         }
//         .Select__menu-list {
//             top: 0px;
//             padding: 0px;
//             height: auto;
//         }
//         .Select__indicator-separator {
//             background-color: #fff;
//         }
//         .Select__option {
//             height: 40px;
//             border: solid #ccc;
//             border-width: 1px;
//             border-top-width: 0px;
//             font-size: 12px;
//             &:hover {
//                 background-color: $black4-color;
//             }
//         }
//         .Select__option--is-selected  {
//             background-color: #fff;
//             color: inherit;
//             font-weight: bold;
//         }
//         .Select__option--is-focused  {
//             background-color: $black4-color;
//         }
//         }
//     > ul {
//         font-size: 12px;
//         color: #B5B5B5;
//     }
//     .custom-select option {
//             margin: 16px !important;
//         }
//         .option {
//             height: 40px;
//             margin: 16px !important;
//         }
//     .filter-tokenizer, .padding-for-clear-all {
//         padding: 0 !important;
//         height: 40px;
//         .typeahead {
//             padding: 0;
//         }
//         .filter-input-group {
//             overflow-y: hidden;
//         }
//     }
//     display: flex;
//     flex-direction: column;
//     .nav-tabs {
//         margin: 20px 0px 20px 0px;
//         width: fit-content;
//         padding-bottom: 0px;
//         > :first-child {
//             border-right: 2px solid $black1-color;
//         }
//         .active {
//             font-weight: bold;
//         }
//     }
//     .row {
//         justify-content: space-between;
//         align-items: center;
//         input, select, .token-collection {
//             height: 40px;
//             &:focus {
//                 background-color: white !important;
//             }
//         }
//     }
//     .col-6 {
//         height: 100%;
//     }
//     .col-12 {
//         padding: 0;
//     }
//     .Switches__Button--Block {
//         .form-group {
//             margin: auto;
//             display: flex;
//             align-content: center;
//             label {
//                 margin: auto;
//                 color: $black2-color;
//             }
//         }
//         > :last-child {
//             display: flex;
//             justify-content: flex-end;
//         }
//         .Cancel__Button {
//             background-color: $cancel-color;
//             margin-right: 16px;
//         }
//     }
//     label {
//         margin-top: 10px;
//         font-size: 11px;
//         font-weight: bold;
//     }
//     .Options__Inputs--Section {
//         display: flex;
//         align-items: flex-start;

//         .form-group {
//             // width: 100%;
//         }
//     }

//     .tab-content {
//         width: -webkit-fill-available;
//     };
//     .Options__Inputs--Section, .Axis__Configuration--div {
//         //padding: 0px 17px;
//         padding-left: 0px;
//         padding-right: 0px;
//         margin-top: 0px;
//         background-color: transparent;
//         // margin-left: -15px;
//         // margin-right: -15px;
//         .card-body {
//             background-color: transparent;
//         }
//         button:first-child {
//             float: right;
//             // min-width: 80px;
//             margin-top: 36px;
//             height: 40px;
//             background-color: $cancel-color;
//             color: black;
//             &:hover {
//                 background-color: $cancel-hover;
//                 color: black;
//             }
//         }
//         button:last-child {
//             float: right;
//             // min-width: 80px;
//             margin-top: 36px;
//             height: 40px;
//             background-color: $primary-color;
//             color: $text-color;

//             &:hover {
//                 background-color: $primary-color-hover;
//                 color: $text-color;
//             }
//         }
//     }
//     .Axis__Configuration--tab {
//         input:required {
//             //Fix on Firefox
//             box-shadow: initial;
//         }
//         .row {
//             > :nth-child(2), > :nth-child(4) {
//                 display: flex;
//                 button {
//                     // background-color: $primary-color;
//                     // color: $text-color;
//                 }
//             }
//             > :nth-child(4) > .form-group {
//                 display: flex;
//                 flex-direction: column;
//                 fieldset {
//                     display: flex;
//                     label {
//                         margin-left: 10px;
//                     }
//                 }
//             }
//             > :nth-child(3) > span {
//                 position: absolute;
//                 color: red;
//                 width: max-content;
//             }
//             > :nth-child(5) {
//                 display: flex;
//                 justify-content: flex-end;
//             }
//             .form-group {
//                 // height: 80px;
//                 fieldset {
//                     // height: 100%;
//                     display: flex;
//                     align-items: center;

//                     label {
//                       margin-left: 10px;

//                     }
//                 }
//             }
//             .btn-primary {
//                 // background-color: $primary-light-5;
//                 // margin-right: 8px;

//             }
//         }
//         table {
//             th {
//                 &:nth-child(-n+3) {
//                     width: 100px;
//                 }
//                 &:last-child {
//                     width: 100px;
//                 }
//             }
//         }
//     }
//     .c-form-check-input:focus, .c-switch-input:focus {
//         outline-color: transparent;
//         border: none;
//         outline-style: none;
//         outline-width: 0;
//     }
//     .c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
//         background-color: $primary-light-2;
//         &::before {
//             background-color: white;
//             border: 1px solid $lines-input-color;
//         }
//     }
//     .c-switch-slider {
//         width: 50px;
//         background-color: black;
//     }
//     .c-switch-input:checked ~ .c-switch-slider::before {
//         transform: translateX(25px);
//     }
//     .Options__Inputs--Section.Options__Inputs--top.row  {
//         padding-left: 0px;
//         padding-right: 0px;
//         margin-top: 0px;
//         margin-left: -30px;
//         margin-right: -30px;
//         padding-top: 15px;
//     }

//     .Options__Inputs--Section.Options__Inputs--bottom.row {
//         padding-left: 0px;
//         padding-right: 0px;
//         margin-top: 0px;
//         padding-bottom: 10px;
//         margin-left: -30px;
//         margin-right: -30px;
//     }

//     .Switches__Button--Block.row {
//         margin-top: 20px;
//         border: 0px solid $primary-color;

//         label {
//             color: $grey3-color;

//         }
//         label:nth-child(2) {
//             color: black;
//             margin-left: 10px;
//         }
//     }

//     .c-switch[class*="-3d"] .c-switch-slider {
//         border: 0px;
//         background-color: $grey3-color;
//     }

//     // data axis
//     .axisField.row {
//         margin-top: 25px;
//         background-color: $grey6-color;
//         margin-left: -30px;
//         margin-right: -30px;
//         padding-left: 15px;
//         padding-bottom: 30px;
//         padding-top: 15px;

//         .c-switch{
//             margin-left: 10px;
//             margin-top: -30px;
//         }
//         fieldset label{
//             margin-top: -19px;
//             margin-left: 10px;

//         }

//         .btn.btn-secondary.btn-sm {
//             margin-top: 25px;
//             float:right;

//             margin-right: 0px;
//         }
//     }

//     //style table

//     //Style Table of Series
//     table {
//         width: -webkit-fill-available;
//         margin: 30px 15px;

//         th {
//             background-color: $primary-color;
//             height: 40px;
//             padding:0px;
//             padding-left: 15px;
//             border: 0px;
//             text-transform: uppercase;
//             font-size: 11px;
//             font-weight: bold;
//             color: $text-color;
//         }
//         th:first-child {
//             border-radius: 5px 0px 0px 0px;
//         }
//         th:last-child {
//             border-radius: 0px 5px 0px 0px;
//         }
//         td {
//             font-size: 12px;
//             padding:0px;
//             height: 40px;
//             padding-left: 15px;
//         }
//         tbody > tr > td:last-child {
//             display: flex;
//             justify-content: flex-start;
//             padding-left: 0px;
//             button {
//                 background-color: transparent;
//                 padding: 10px;
//                 border: none;
//                 height: 35px;
//                 width: 35px;

//                 &:focus {
//                     outline: none;
//                 }
//                 .c-icon {
//                     width: 16px; // este
//                     height: 16px;
//                     margin-top: 0px;
//                     padding: 0px;
//                 }
//                 &:hover {
//                     background-color: $primary-color;
//                     border-radius: 5px;
//                     color: $text-color;

//                 }
//                 svg {
//                     margin: 0;
//                 }
//             }
//         }
//         .ant-input {
//             color: $text-color !important;
//         }
//     }

//     .ant-table-container table > thead > tr:first-child th:first-child {
//         border-top-left-radius: 5px;
//     }

//     .ant-table-container table > thead > tr:first-child th:last-child {
//         border-top-right-radius: 5px;
//     }

//     .ant-table-tbody {
//             button {
//               background-color: transparent;
//               width: 35px;
//               height: 35px;
//               border-radius: 5px;
//               margin-right: 3px;

//               &:hover {
//                 background-color: $primary-color;
//                 color: $text-color;
//               }
//             }
//             button.editing {
//               background-color: $primary-color;
//               color: $text-color;
//             }
//           }

//     .c-switch-input:focus, .c-switch-slider {
//         box-shadow: none;
//     }
//     .min-and-max {
//         display: flex;
//         >:nth-child(1) {
//             padding-left: 0;
//         }
//         >:nth-child(2) {
//             padding-right: 0;
//         }
//     }
//     .Axis__Stacked {
//         transition: background 3s;
//     }
//     .modal-footer {
//         button {
//             font-weight: bold;
//             &.Save__Widget--Button {
//                 color: $text-color;
//                 background-color: $primary-color;
//                 padding: 12px 15px;
//                 &:hover {
//                     background-color: $primary-color-hover;
//                 }
//             }
//         }
//     }
//     .card-body {
//         padding: 0px 15px;
//     }

//   }
//   .DynamicChart__Title, .Chart__Title {
//     margin: 20px 40px;
//     font-weight: bold;
//   }
//   .DynamicChart__Canvas, .Chart__Canvas {
//     background-color: white;
//   }

//   .DynamicChart__Module--Component, .Chart__Module--Component {

//     .row {

//         padding-left: 10px;
//         padding-right: 10px;

//     }
//     .groupBy__Row {
//         align-items: start;
//         background-color: transparent;

//         table {
//             margin-top: 35px;
//             height: 300px;
//             overflow-y: scroll;
//             display: block;
//             //border: 1px solid #E5E5E5;
//             border-radius: 5px;

//             .ant-input-wrapper {
//                 //border-bottom: 1px solid $grey1-color;
//                 background-color: transparent;
//                 color: $text-color;
//                 input {
//                     height: 40px;
//                     background-color: transparent;
//                     color: $text-color;
//                 }
//             }
//             .ant-input-group-addon {
//                 background-color: transparent;
//             }
//         }

//         button:hover {
//             background-color: inherit;
//             cursor: default;
//         }
//         th, td, tr {
//             //background-color: white;
//             height: 40px;
//             border-bottom: 1px solid $grey4-color;
//             &:nth-child(2) {
//                 width: 100%;
//                 height: 40px;
//             }

//         }
//         tr:first-of-type {
//             position: sticky;
//             top: 0;
//             z-index: 1;
//         }
//         th {
//             &:last-of-type {
//                 width: 200px;
//             }
//         }
//         tr {
//             display: block;
//             :nth-child(3) {
//                 min-width: 416px;
//             }
//         }
//         td > input {
//             padding-left: 0;
//             height: 40px;
//         }

//         th:nth-child(3) {
//             min-width: 200px;
//         }

//         input {
//             border: none;
//             background-color: transparent;

//             &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//                 color: $text-color;
//                 opacity: 1; /* Firefox */
//             }

//             &:-ms-input-placeholder { /* Internet Explorer 10-11 */
//                 color: $text-color;
//             }

//             &::-ms-input-placeholder { /* Microsoft Edge */
//                 color: $text-color;
//             }
//         }

//         input:focus {
//             background-color: transparent;
//         }
//     }

//   }

// codigo diogo

.Dynamic__Chart--View--Container,
.Chart--View--Container {
  .full-height {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }

  .Actions__Container {
    display: flex;
    height: inherit;
    padding-right: 15px;
    z-index: 1;
    .Drawer {
      width: 0px;
      overflow: hidden;
      transition: width 1s;
      background-color: $white1-color;
      overflow-x: auto;
      &.visible {
        width: 250px;
        border-left: 1px solid $black5-color;
      }
      .Drawer__Content--div {
        padding: 16px;
        height: -webkit-fill-available;
        hr.After__Search--hr {
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }
      .dropdown-toggle {
        background-color: $white1-color;
      }
      .Single__Filter--Container {
        background-color: $white1-color;
        overflow-x: auto;
        height: 70%;
        height: calc(100% - 135px);
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $grey2-color;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $grey1-color;
        }
        & > button {
          background-color: $white1-color;
          border: 1px solid $grey3-color;
          width: -webkit-fill-available;
          //border: none;
          font-weight: bold;
          min-width: fit-content;
          text-transform: uppercase;
        }
        .Filter__Options {
          height: 0px;
          overflow: hidden;
          transition: height 1s;
          background-color: $white1-color;
          padding: 0px;
          &.visible {
            padding: 8px;
            height: 200px;
          }
          input {
            border: none;
            border-bottom: 1px solid;
          }
          .Options__Container {
            overflow: scroll;
            max-height: 150px;
            & > span {
              display: flex;
              align-items: center;
              height: 30px;
              .ant-checkbox-wrapper {
                margin-right: 8px;
              }
              p {
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
      .Search__Input {
        .ant-input-wrapper {
          //border-bottom: 1px solid;
        }
        button {
          border: none;
        }
      }
      .Select__Filters {
        margin-bottom: 16px;
        min-width: max-content;
      }
      .Single__Filter--row {
        display: flex;
        align-items: center;
        padding: 8px;
        padding-left: 0;
        p {
          padding: 0;
          margin: 0;
        }
        label {
          margin-right: 8px;
        }
      }
    }
    .Filter__HandleSideBar {
      border: none;
      background-color: transparent;
      width: 40px;
      height: 70px;
      padding: 0;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      transition-duration: 200ms;
      &:hover {
        border-left: $primary-color 4px solid;
      }
      &.Open {
        border-left: $primary-color 4px solid;
      }
      span {
        display: inline-block;
        transform: rotate(90deg);
        white-space: nowrap;
        padding: 0;
      }
    }
    .Save__Changes--Button {
      margin-top: auto;
    }
    .View__Action--Buttons {
      height: 40px;
      padding: 8px;
      background-color: transparent;
      border-radius: 4px;
      &:hover {
        background-color: $primary-light-4;
      }
      &.Delete__Axis--Button:hover {
        background-color: $primary-light-4;
      }
      &.Delete__Visible {
        background-color: $primary-light-4;
      }
    }
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .Axis__Drawer {
    overflow-y: overlay !important;
    overflow-x: clip;

    .Axis__Draggable {
      width: 100%;
    }
    .Header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 4px 16px;
      h4 {
        width: 100%;
        text-align: center;
      }

      .Axis__Actions--Tab {
        display: flex;
        button {
          padding: 8px;
          background-color: transparent;
          border-radius: 4px;
          margin-left: 7px;
          &:hover {
            background-color: $primary-color;
            color: $text-color;
          }
          &.Delete__Axis--Button:hover {
            background-color: $danger-color;
            color: $text-color;
          }
          &.Delete__Visible {
            background-color: $danger-color-hover;
          }
        }
      }
    }
    .Axis__Tabs {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $black4-color;
      .Tab__Info {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        p {
          margin: 0;
        }
        :first-child {
          font-weight: bold;
          margin-left: 10px;
        }
        :nth-child(2) {
          font-size: 10px;
          height: 0px;
          transition-duration: 0.5s;
          overflow: hidden;
        }
      }
      :hover > span > :nth-child(2) {
        height: 12px;
        margin-left: 10px;
      }
      button {
        background-color: $primary-light-4;
        color: black;
        display: flex;
        align-items: center;
        outline: none;
        p {
          padding: 0;
        }
        &.Axis__Tabs--Single:hover {
          background-color: $primary-light-4;
        }
      }
      .Axis__Tabs--Single {
        height: 100%;
      }
      .Delete__Axis--Button {
        width: 30px;
        height: 30px;
        margin: 5px;
        &:hover {
          background-color: $danger-color-hover;
          border-radius: 4px;
        }
      }
    }
    button {
      width: 100%;
      display: flex;
    }
  }
  .list-container {
    overflow: hidden;
    background-color: $white2-color;
    min-width: max-content;
    height: 0;
    min-height: 0;
    padding: 0 16px;
    &.Open {
      height: auto;
      min-height: 21px;
    }
    &.Is_Dragging_Over {
      min-height: 21px;
      opacity: 0.8;
      background-color: $primary-light-5;
    }
    &:not(.Is_Dragging_Over) {
      transition-duration: 750ms;
    }
  }
}
.item-container {
  padding: 2px 8px;
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  display: flex;
  > img {
    width: 25px;
    height: 25px;
  }
  > p {
    padding: 0 8px;
    align-items: center;
    display: flex;
  }
}
.Axis__Draggable {
  position: relative;
}
.ant-modal-content {
  min-width: max-content;
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    .Cancel--Button {
      height: 40px;
      margin: auto 20px;
      background-color: $black4-color;
      padding: 12px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      &:hover {
        background-color: $black5-color;
      }
    }
    .Save--Button {
      font-weight: bold;
      height: 40px;
      margin: auto 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-color;
      background-color: $primary-color;
      padding: 12px 15px;
      width: 160px;
      &:hover {
        background-color: $primary-color-hover;
        color: $text-color;
      }
      &:disabled {
        background-color: #f5f5f5;
        color: grey;
      }
    }
  }
}
.Create__Axis--Fast--Form {
  display: flex;
  min-width: max-content;
  .form-group {
    padding: 8px;
    fieldset {
      display: flex;
      label {
        margin-left: 10px;
      }
    }
  }
  button {
    font-weight: bold;
    height: 40px;
    margin: auto 20px;
    display: flex;
    align-items: center;
    color: black;
    background-color: $primary-light-2;
    padding: 12px 15px;
    &:hover {
      background-color: $primary-color-hover;
    }
    &:disabled {
      background-color: #f5f5f5;
    }
  }
}

//   .Series__Table--Row.row-dragging {
//     font-size: 12px;
//     height: 50px;
//     background-color: #FAFAFA;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     #Move__Vertically {
//         width: 20px;
//         &:hover {
//             cursor: move;
//         }
//     }
//     td {
//         color: #000;
//         padding: 0px;
//         border: 0px;
//         font-size: 12px;
//         font-weight: 400;
//         vertical-align: middle;
//         padding-left: 15px;
//         height: 40px;
//     }
//     td:last-child {
//         display: flex;
//         justify-content: flex-start;
//         align-items: center;
//         padding-left: 0px;
//         height: fit-content;
//         height: 40px;
//         button {
//             //background-color: red;
//             width: 30px;
//             height: 35px;
//             padding: 10px;
//             border: none;
//             &:focus {
//                 outline: none;
//             }
//             .c-icon {
//                 width: 20px;
//                 height: 20px;
//             }
//             &:hover {
//                 background-color: $primary-color;
//                 color: $text-color;
//             }
//             svg {
//                 margin: 0;
//             }
//         }
//     }
//   }
.Fast__Add__Axis {
  .ant-modal-body {
    padding: 8px 24px;
  }
  label {
    font-weight: bold;
  }
}

#drag-axis-side-tab > div {
  display: flex;
  > p {
    padding: 0 8px;
    align-items: center;
    display: flex;
  }
}

.ant-modal-mask {
  z-index: 1040;
}
.ant-modal-wrap {
  z-index: 1042;
}
