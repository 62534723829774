// Slack bot
.ReactSlackChat__card___3w9UW {
  width: 40px !important;
  height: 45px !important;
  right: 30px !important;
  bottom: -10px !important;
  box-shadow: 0 0 !important;
}

.ReactSlackChat__helpHeader___1zfWG {
  background-color: $primary-light-1 !important;
  padding: 10px !important;
  padding-top: 4px !important;
  text-align: center;
}

.ReactSlackChat__helpHeader___1zfWG::before {
  content: '?';
  font-size: 20px;
  color: #fff;
}

.ReactSlackChat__card___3w9UW h2 {
  color: #000 !important;
  display: none;
}

.ReactSlackChat__chatHeader___3Tlfe {
  background-color: $primary-color !important;
}

.ReactSlackChat__chat__back___xUDcG:before {
  border: 2px solid #000 !important;
  border-right: none !important;
  border-bottom: none !important;
}

.ReactSlackChat__chat__person___2u9ec {
  color: #000 !important;
}

.ReactSlackChat__chat__status___1NTQA {
  color: #000 !important;
}

.ReactSlackChat__attachmentIcon___2DWqQ {
  bottom: 0px !important;
}

.ReactSlackChat__card___3w9UW.ReactSlackChat__active___1osoX {
  height: 500px !important;
  width: 340px !important;
  bottom: 0px !important;
}

.ReactSlackChat__card___3w9UW.ReactSlackChat__active___1osoX h2 {
  color: #000 !important;
  display: initial;
}

.ReactSlackChat__card___3w9UW.ReactSlackChat__active___1osoX .ReactSlackChat__helpHeader___1zfWG {
  padding-top: 10px !important;
}

.ReactSlackChat__card___3w9UW.ReactSlackChat__active___1osoX .ReactSlackChat__helpHeader___1zfWG::before {
  content: '';
  font-size: 20px;
  color: #fff;
}

.ReactSlackChat__chat__messages___1kJlp {
  height: auto !important;
  top: 100px !important;
  bottom: 55px !important;
  width: 98% !important;
  width: calc(100% - 7px) !important;
  padding-top: 0px !important;
}

.ReactSlackChat__chat__input___1-Hr1 {
  width: 100% !important;
  width: calc(100% - 10px) !important;
  left: 5px !important;
  right: 5px !important;
  bottom: 5px !important;
}
