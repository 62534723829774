
//  Button
.btn {
  color: #000;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  padding: 8px 10px;
  border: 0px;
  // min-height: 30px;
}

.input-group-text .btn {
  padding: 0px;
}
.btn:focus,
.btn.focus {
  box-shadow: initial;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 8px 11px;
}
.btn-primary {
  color: $text-color;
  background-color: $primary-color;
}

.btn.disabled {
  //color: $color-disabled;
  color: #9A9A9A;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: $text-color-hover;
  background-color: $primary-color-hover;
  //border-radius: 50px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $black5-color;
  color: $grey2-color;
  border-color: $black5-color;
}

.btn-primary.active {
  background-color: $primary-color-active;
}

.btn-primary.active:hover {
  color: $text-color-hover;
  background-color: $primary-color-hover;
}

.btn-secondary {
  color: black;
  background-color: $cancel-color;
}

.btn-secondary:hover {
  background-color: $cancel-color;
}

.ant-btn {
  background-color: $cancel-color;
  border-radius: 5px;
  height: 40px;
  border: 0px;
  min-width: 80px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
}

.ant-btn-primary {
  background-color: $primary-color;
  color: $text-color;
  min-width: 80px;
  min-height: 40px;
  margin-top: 37px;
  border-radius: 5px;
}

.btn-light:hover {
  color: $text-color;
}

.btn-light.active,
.btn-light.active:hover {
  background-color: $primary-color;
  font-size: 12px;
  color: $text-color;
}

.Map__Results--Column {
  button.ant-btn.ant-btn-primary {
    background-color: $primary-light-1;
    min-width: 115px;
    color: $text-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
  }

  button.ant-btn.ant-btn-primary:hover {
    background-color: $primary-light-1;
    min-width: 115px;
    color: $text-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
  }
}

// button modal add
button#Add--button {
  background-color: $secondary-color;
  color: $text-color;
  min-width: 110px;
  min-height: 40px;
  margin-top: 37px;
  border-radius: 5px;
  margin-left: 40px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: black;
  background-color: $cancel-hover;
  border: 0px;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $black5-color;
}

.btn-sm {
  height: 40px;
}

.btn-success {
  color: $text-color;
  background-color: $primary-light-2;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: $text-color;
  background-color: $primary-color-hover;
  border: 0px;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: $black5-color;
}

.btn-warning {
  color: $text-color;
  background-color: $warning-color;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: $text-color;
  background-color: $warning-color-hover;
  border: 0px;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: $black5-color;
}

.btn-danger {
  color: #000;
  background-color: $danger-color;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: $danger-color-hover;
  border: 0px;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: $black5-color;
}

.btn-outline-primary {
  color: $primary-color;
  border: 2px solid $primary-color;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primary-color;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-outline-secondary {
  color: $secondary-color;
  border: 1px solid $secondary-color;
}
.btn-outline-secondary:hover {
  color: #4f5d73;
  background-color: $secondary-color;
  border-color: $secondary-color;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: $secondary-color;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: #4f5d73;
  background-color: $secondary-color;
  border-color: $secondary-color;
}
.show > .btn-outline-secondary.dropdown-toggle {
  color: #4f5d73;
  background-color: $secondary-color;
  border-color: $secondary-color;
}
.btn-outline-success {
  color: $success-color;
  border: 1px solid $success-color;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: $success-color;
  border-color: $success-color;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: $success-color;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $success-color;
  border-color: $success-color;
}
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: $success-color;
  border-color: $success-color;
}
.btn-outline-info {
  color: $info-color;
  border: 1px solid $info-color;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: $info-color;
  border-color: $info-color;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: $info-color;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $info-color;
  border-color: $info-color;
}
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: $info-color;
  border-color: $info-color;
}
.btn-outline-warning {
  color: $warning-color;
  border: 1px solid $warning-color;
}
.btn-outline-warning:hover {
  color: #4f5d73;
  background-color: $warning-color;
  border-color: $warning-color;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: $warning-color;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #4f5d73;
  background-color: $warning-color;
  border-color: $warning-color;
}
.show > .btn-outline-warning.dropdown-toggle {
  color: #4f5d73;
  background-color: $warning-color;
  border-color: $warning-color;
}
.btn-outline-danger {
  color: $danger-color;
  border: 1px solid $danger-color;
  width: 40px;
  height: 40px;
  margin-top: 12px;
  .c-icon {
    margin: -10px !important;
  }
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: $danger-color;
  border-color: $danger-color;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: $danger-color;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $danger-color;
  border-color: $danger-color;
  width: 40px;
  height: 40px;
}
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: $danger-color;
  border-color: $danger-color;
}
.btn-outline-light {
  color: #ebedef;
  border: 1px solid #ebedef;
}
.btn-outline-light:hover {
  color: #4f5d73;
  background-color: #ebedef;
  border-color: #ebedef;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ebedef;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #4f5d73;
  background-color: #ebedef;
  border-color: #ebedef;
}
.show > .btn-outline-light.dropdown-toggle {
  color: #4f5d73;
  background-color: #ebedef;
  border-color: #ebedef;
}
.btn-outline-dark {
  color: $black1-color;
  border: 1px solid $black1-color;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: $black1-color;
  border-color: $black1-color;
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: $black1-color;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $black1-color;
  border-color: $black1-color;
}
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: $black1-color;
  border-color: $black1-color;
}

.btn.btn-link.btn-sm:hover {
  text-decoration: none;
  color: $primary-color-hover;
}

.btn.btn-link.btn-sm:focus {
  text-decoration: none;
}

.btn.btn-link:hover {
  color: $primary-color-hover;
}

.btn-pill {
  text-decoration: none;
  color: $primary-color;
  border: 2px solid $primary-color;
  padding: 0px;
  font-size: 12px;
  letter-spacing: 0.3px;
  height: 35px !important;
  padding: 5px 13px 5px 13px;

  .c-icon {
    width: 20px !important;
    height: 20px !important;
    color: $primary-color;
    margin-right: 6px !important;
    margin-top: 0px !important;
    margin-left: -2px;
  }
}

.btn-pill:hover {
  text-decoration: none;
  color: $primary-color-hover;
  border-color: $primary-color-hover;

  .c-icon {
    color: $primary-color-hover;
  }
}

/*
.btn-block {
  max-width: 226px;
}
*/

//  Icons
.btn-lg i,
.btn-group-lg > .btn i,
.btn-lg .c-icon,
.btn-group-lg > .btn .c-icon {
  width: 1.09375rem !important;
  height: 1.09375rem !important;
  margin: 0.27344rem 0.27344rem;
  margin-left: -10px;
  margin-right: 10px;
}

.btn-arrow {
  padding-top: 0 !important;
  width: auto !important;
  height: 35px !important;
  font-size: 16px;

  .c-icon {
    padding: 4px !important;
    width: 35px !important;
    height: 35px !important;
    margin-right: 12px;
    margin-top: 0px !important;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 5px;
  }
}

.btn-arrowLabel {
  margin-top: 0px;
  font-size: 16px !important;
  letter-spacing: 0.2px;
  margin-top: -4px !important;
  height: 40px !important;

  .c-icon {
    margin-right: 8px;
    margin-top: 1px !important;
    margin-left: 0px !important;
  }
}

.btn-arrowLabel:hover {
  color: $primary-color;
}

.btn-square {
  border: 2px solid $primary-color;
  font-size: 13px !important;
  height: 35px !important;
  padding: 5px 13px 5px 10px;
  color: $primary-color;

  .c-icon {
    padding: 2px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 0px !important;
    color: $primary-color;
    margin-right: 5px !important;
    margin-left: -2px !important;
    margin-top: -3px !important;
  }
}

.btn-square:hover {
  border: 2px solid $primary-color-hover;
  color: $primary-color-hover;

  .c-icon {
    color: $primary-color-hover;
  }
}

button.btn.btn-outline-link.btn-lg {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background-color: white;
  margin-top: 10px;

  .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
    margin-left: 1px !important;
    margin-top: 1px !important;
  }
}

// butons manage resorces

.button,
.btn-group .button {
  padding: 0.4em 0.65em;
  border-radius: 5px !important;
}

.btn-outline-link {
  border: 1px solid;
  border: 0px !important;
  color: $text-color;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  height: 35px;
  //width: 35px;

  .c-icon {
    padding: 0px;
    margin-left: -1px;
  }
}

.btn-outline-link:hover {
  background-color: $primary-color;
  color: $text-color;
}

.btn-outline-link.active {
  background-color: $primary-color;
  color: $text-color;
}

.btn-outline-link:last-child {
  margin-right: 0px;
}

.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 5px !important;
}

.btn-preview {
  background-color: #c3e61c;
}

.btn-preview:hover {
  background-color: #a9c619;
  color: $text-color;
}

.btn-dark {
  background-color: white;
  color: black;
  font-size: 11px;
  height: 40px;
  //min-width: 110px;
  margin-top: 0px;
}

.btn-dark:hover,
.btn-dark:active,
.btn-dark.active,
.btn-dark:focus {
  background-color: $primary-color;
  color: $text-color;
}

//btn modal
.dashboard-widget.newtable-component.row {
  .btn.btn-secondary.btn-sm.btn-block,
  .btn-group-sm > .btn.btn-secondary.btn-block {
    background-color: $primary-light-2;
    //padding: 9px;
    border: 0px;
    width: auto;
  }
}

// modalform buttons
.modelform-component,
.prodtrack-modelform,
.dashboard-widget .card,
.card-footer {
  .button.cancelButton.btn.btn-light.btn-sm {
    background-color: $cancel-color;
    margin-bottom: 10px;
    min-width: 40px;
    font-size: 12px;
    color: black;
    &:hover {
      background-color: $cancel-hover;
       color: $text-color;
    }
  }
}

// modal buttons
.modal-footer {

  .ant-btn {
    background-color: $primary-color;
  }



  .ant-btn.Create__Page--Button,  
  .ant-btn.Create__Page--Button:active,   
  .ant-btn.Create__Page--Button:focus  {
    background-color: $primary-color;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    &:hover {
      background-color: $primary-color-hover;
      color: $text-color;
    }
  }

  .ant-btn[disabled] {
   border-radius: 5px;
    background-color: $background-disabled;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
 }
 
  .btn {
    background-color: $cancel-color;
    color: $text-color;
    font-size: 11px;
    height: 40px;
    margin-right: 10px;
    float: right;

    &:hover {
      background-color: $cancel-hover;
      color: $text-color;
    }
  }

  button.Save__Widget--Button {
    background-color: $primary-color;
    color: $text-color;

    &:hover {
      background-color: $primary-color-hover;
      color: $text-color;
    }
  }

  .btn-danger {
    color: black;
    background-color: $danger-color;
    &:hover {
      background-color: $danger-color-hover;
    }
  }

  .btn-primary {
    background-color: $primary-color;
    color: $text-color;

    &:hover {
      background-color: $primary-color-hover;
      color: $text-color;
    }
  }

}

.ant-btn.ant-btn-submit.submitButton, 
.ant-btn.ant-btn-submit.submitButton:active,
.ant-btn.ant-btn-submit.submitButton:focus {
    background-color: $primary-color;
    margin-right: 15px;
    font-size: 11px;
    padding: 0px 14px;
    min-width: auto;
    color: $text-color;
    float: right;
    margin-right: 0px;
    // min-width: none;
     &:hover, &:active {
      background-color: $primary-color-hover;
    }

  }
  .cancelButton.btn.btn-light.btn-sm {
    float: right;
    margin-right: 16px;
  }

  .btn.icon.primary {
    background-color: $primary-light-3;
    color: $text-color;
    width: 40px;
    height: 40px;
    margin: 6px;
    padding: 0px;

    .c-icon {
      width: 20px; 
      height: 20px;
    }
    &:hover {
      background-color: $primary-color;

    }
  }
