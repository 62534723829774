//   Header
.c-header {
  border-bottom: 0px;
  min-height: 51px;

  .bg-primary {
    // background-color: red;
  }
}

// acount
.profile-name {
  color: $text-color;
  background-color: $primary-color;
}

.c-main {
  background-color: #f5f5f5;
}

.dropdown-menu {
  border-radius: 5px;
}

// account menu dropdown
.c-header .dropdown-menu,
.navbar .dropdown-menu,
[data-display^="static"] ~ .dropdown-menu {
  border-radius: 5px 5px 5px 5px;
  // margin-right: -10px !important;
}

//triangulo
.c-header .dropdown-menu::before {
  background-color: transparent !important;
  display: none;
}

.dropdown-header {
  background-color: $primary-color;
  color: $text-color;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  //  border-radius: 5px 5px 0px 0px;
}
.c-header-nav .dropdown-item {
  border: 0px;
}

.c-header,
.c-header-nav {
  min-height: 51px;
  background-color: $header-color;
  background: $header-color;
}

.c-header-searchbar {
  margin-left: 12px;
}
.c-header-searchbar input {
  border-color: transparent;
}
.c-header-searchbar input:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: initial;
}
//links header
.c-header-searchbar .btn {
  border-color: transparent;
  padding: 0px;
  border-radius: 0px;
  font-weight: 500;
  //color: $grey1-color;
  color: black;

  //text-transform: uppercase;
  line-height: 40px;
}

.c-header {
  .toolbar-link {
    height: 100%;

    .nav-link {
      padding: 0.5rem 0.5rem;
    }
  }
  .active.toolbar-link {
    background-color: $primary-color;
    color: $text-color;
  }
  .navbar {
    padding: 0 1rem;
  }
}

.c-header-rightnav {
  margin-right: 15px;
}
.c-header-rightnav .c-header-nav-item {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

// administrador / user
.c-header-rightnav .c-header-nav-items {
  margin-left: 15px !important;
}

.c-header-rightnav .c-header-nav-link,
.c-header-rightnav .c-header-nav-btn {
  padding-right: 0;
  padding-left: 0;
  color: black !important;
}

// avatar admnistrador / user
.c-header-rightnav .c-avatar {
  margin-right: 5px;
  //background-color: red;
}

.profile-name {
  background-color: #f5f5f5;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
}

html:not([dir="rtl"]) .c-header-rightnav .dropdown-toggle::after {
  margin-left: 10px;
}
.c-header-rightnav .header-separator {
  width: 1px;
  height: 25px;
}
.c-header .dropdown-menu,
.navbar .dropdown-menu,
[data-display^="static"] ~ .dropdown-menu {
  padding: 0px;
  border-radius: 0px;
  border: 0px;
  filter: drop-shadow(0px 2px 1px #ddd);
}
.c-header .dropdown-menu::before,
.navbar .dropdown-menu::before,
[data-display^="static"] ~ .dropdown-menu::before {
  content: "\A";
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent $primary-color transparent;
  position: absolute;
  right: 10px;
  top: -8px;
}
.dropdown-toggle::after {
  display: none;
}

@media (max-width: 1000px) {
  .d-md-block {
    display: flex !important;
    background-color: $primary-color;
    width: 51px;
  }
  .c-header .c-header-toggler-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: -30px;
    margin-top: 6px;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTIxLjUsMzcuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsODAuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsMTIzLjYyNXYxMC43NWgxMjl2LTEwLjc1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+")
      50% 50% no-repeat;
    background-size: 100%;
  }
  .c-header .c-header-toggler-icon:hover {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: -30px;
    margin-top: 6px;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTIxLjUsMzcuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsODAuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsMTIzLjYyNXYxMC43NWgxMjl2LTEwLjc1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+")
      50% 50% no-repeat;
    background-size: 100%;
  }
}

@media (max-width: 790px) {
  .c-header .c-header-toggler-icon {
    margin-left: 0px !important;
  }
}

@media (max-width: 570px) {
  .c-header-searchbar {
    margin-left: 0px !important;
    //padding-left: 12px !important;
  }

  .c-header-nav.breadcrumbs-list {
    max-width: calc(100% - 115px);

    .navbar {
      max-width: 100%;

      .btn {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(50vw - 67px);
        padding-left: 0px;
        text-align: left;
      }
    }
  }
}

@media (max-width: 360px) {
  .c-header-nav.breadcrumbs-list {
    max-width: calc(100% - 125px);
  }
  .c-header-nav.breadcrumbs-list .navbar .btn {
    max-width: calc(50vw - 80px);
  }
}
