//  Filter
.filter-input-group {
  margin: 0px !important;
  min-height: 40px;
}
.filter-input-group {
  //padding: 0px 5px !important;
  min-height: 40px;
}
.filter-tokenizer,
.filter-tokenizer.padding-for-clear-all {
  width: 100% !important;
  border-radius: 5px !important;
  padding: 0px !important;
  min-height: 41px;
}
.filter-tokenizer .typeahead input {
  width: 20px !important;
  height: 25px !important;
}

.filter-tokenizer ul.typeahead-selector {
  border: 0px !important;
  box-shadow: 1px 1px 0px #888 !important;
  background-color: #fff !important;
  /*height: 40px;*/
}
.filter-tokenizer-list__item {
  text-transform: capitalize;
}
.filter-tokenizer ul.typeahead-selector li {
  background-image: initial !important;
}
.filter-tokenizer ul.typeahead-selector li.header {
  background-color: $grey5-color !important;
  color: black !important;
}
.filter-tokenizer ul.typeahead-selector li a {
  width: 100% !important;
}
.filter-tokenizer ul.typeahead-selector .hover a,
.filter-tokenizer ul.typeahead-selector li a:hover,
.typeahead ul.typeahead-selector .hover a,
.typeahead ul.typeahead-selector li a:hover {
  background-color: $white3-color !important;
  color: #000 !important;
}
.filter-tokenizer,
.filter-tokenizer.padding-for-clear-all {
  height: 40px;
  padding: 0px;
}
.filter-tokenizer .typeahead-token {
  background-image: initial !important;
  margin: 5px 0px 5px 5px !important;
  padding: 2px !important;
  padding-left: 7px !important;
  font-weight: 100 !important;
  border-radius: 3px !important;
  font-size: 12px;
  height: 30px !important;
  vertical-align: middle !important;
  border: 0px !important;
}
.typeahead-token-close {
  font-family: "Arial Narrow", sans-serif;
  padding-bottom: 0px;
  padding-top: 0px !important;
  padding-right: 5px;
  padding-left: 7px;
  margin-top: 0px !important;
  color: black;
  vertical-align: middle !important;
  font-size: 17px;
  font-weight: bold !important;
}
.filter-tokenizer .clear-all {
  right: 4px !important;
  width: 20px !important;
  margin-top: -4px;
}
