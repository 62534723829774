
// Variable overrides
//$primary-color: #03A9F4;
$primary-color: #fea30b;
$primary-color-hover: #f29b03;
$primary-color-active: #f29b03;

$text-color: black;
$text-color-hover: black;

$primary-color-light:#fea30b;
$primary-light-1: #ffac1d;
$primary-light-2: #ffb534;
$primary-light-3: #fbbb4c;
$primary-light-4: #fcc462;
$primary-light-5: #ffcc73;




$dark-color: #2C2B2A;
$dark-color-hover: #191918;

$header-color: white;

$color-disabled: #9A9A9A;
$background-disabled: #E6E6E6;



// Light mode
$body-background: #F5F5F5; 
$background: #FFFFFF; 
$menu-open: #FAFAFA; 
$input-color: #000;
$lines-input-color: #D8D8D8;

$input-boxes: #B5B5B5;

$font-color: #000000;

$cancel-color:#DBDBDB;
$cancel-hover: #A09F9F;
$cancel-disable: #E7E7E7;

// grey
$grey-color: #F5F5F5;
$grey-color-2: #EEEEEE;
$grey-color-3: #DBDBDB;
$grey-color-dark: #848484;

// secondary color
$secondary-color: #fea30b;
$secondary-color-hover: #f29b03;

$secondary-light-1: #33C1E8;
$secondary-light-2: #66D1EE;
$secondary-light-3: #99E0F4;
$secondary-light-4: #CCF0FA;

// yellow
$yellow-color: #FFCD00;
$yellow-light-1: #FFD733;
$yellow-light-2: #FFE166;
$yellow-light-3: #FFEB99;
$yellow-light-4: #FFF5CC;

// blue

$blue-color: #0CDDE5;
$blue-light-1: #3CE3EA;
$blue-light-2: #6DEAEF;
$blue-light-3: #9DF1F5;
$blue-light-4: #CEF8FA;

//Prodtrack

$info-color: #57BCF3;
$info-color-hover: #9AD7F8;

$success-color: #68DEC6;
$success-color-hover: #A4EBDD;

$warning-color: #FFD24F;
$warning-color-hover: #FFE495;

$danger-color:#fe6060;
$danger-color-hover:#ec3939;
$danger1-color:rgb(255, 122, 122);
$danger2-color:rgb(255, 144, 144);
$danger3-color:rgb(255, 181, 181);

$blue1-color: #57BCF3;
$blue2-color: #78C9F5;
$blue3-color: #9AD7F8;
$blue4-color: #BCE4FA;
$blue5-color: #DDF2FD;

$lightblue1-color: #34DAF0;
$lightblue2-color: #7EDDEA;
$lightblue3-color: #9EE6EF;
$lightblue4-color: #BEEEF5;
$lightblue5-color: #DFF7FA;

$green1-color: #68DEC6;
$green2-color: #86E4D1;
$green3-color: #A4EBDD;
$green4-color: #C2F2E8;
$green5-color: #E1F9F4;
$green6-color: #E1F9F4;


$lightgreen1-color: #8EE6B5;
$lightgreen2-color: #A4EBC3;
$lightgreen3-color: #BBF0D2;
$lightgreen4-color: #D2F5E1;
$lightgreen5-color: #E8FAF0;

$fluorescentgreen1-color: #CCF06F;
$fluorescentgreen2-color: #D6F38B;
$fluorescentgreen3-color: #E0F6A8;
$fluorescentgreen4-color: #EBF9C5;
$fluorescentgreen5-color: #F5FCE2;

$black1-color: #000000;
$black2-color: #848484;
$black3-color: #B5B5B5;
$black4-color: #DBDBDB;
$black5-color: #E5E5E5;

$white1-color: #EEEEEE;
$white2-color: #F5F5F5;
$white3-color: #FAFAFA;

$yellow1-color: #FFD24F;
$yellow2-color: #FFDB72;
$yellow3-color: #FFE495;
$yellow4-color: #FFEDB8;
$yellow5-color: #FFF6DC;

$red1-color: #FF7272;
$red2-color: #ff8e8e;
$red3-color: #ff9e9e;
$red4-color: #ffbbbb;
$red5-color: #ffd4d4;

$orange1-color: #FF9357;
$orange2-color: #FFA878;
$orange3-color: #FFBE9A;
$orange4-color: #FFD4BC;
$orange5-color: #FFE9DD;

$pink1-color: #F295B9;
$pink2-color: #F4AAC7;
$pink3-color: #F7BFD5;
$pink4-color: #FAD4E3;
$pink5-color: #FDEAF1;

$purple1-color: #D2A8F0;
$purple2-color: #DBB9F3;
$purple3-color: #E4CBF6;
$purple4-color: #EDDCF9;
$purple5-color: #F6EEFC;

$lavender1-color: #B0BEED;
$lavender2-color: #BFCBF0;
$lavender3-color: #CFD8F4;
$lavender4-color: #DFE5F8;
$lavender5-color: #EFF2FC;

$grey1-color: #848484;
$grey2-color: #B5B5B5;
$grey3-color: #DBDBDB;
$grey4-color: #E5E5E5;
$grey5-color: #EEEEEE;
$grey6-color: #F5F5F5;
$grey7-color: #FAFAFA;


