.basic-multi-select {
    height: auto;
    z-index: initial !important;


    .css-1wa3eu0-placeholder {
        margin-left: 5px;
        font-size: 13px;
    }

    .customReactSelectMenu {
        background-color: #fff;
        z-index: 11 !important;

        margin-top: 6px;
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        
        background-color:hsl(0,0%,100%);
        border-radius:4px;
        box-shadow:0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1);
        
        .btn {
            color: $primary-color;
            margin-top: 0px;
            background-color: transparent;
            text-align: left;
            font-size: 12px;
            max-width: 100%;
        }
    }

    .select__control, .select__indicators {
        z-index: initial !important;
    }

    .select__menu-list {
        //background-color: red;
        z-index: 11 !important;
        color: black;

    }

    .select__option {
        background-color: #fff;
        z-index: 11 !important;
        color: black;

    }

    .css-12jo7m5 {
        font-size: 12px;
    }

    .select__option:hover {
        background-color: $grey-color;
    }

    .select__option--is-selected {
            font-weight: bold;
            background-color: $grey-color;
        }
        

    .customReactSelectMenu + .customReactSelectMenu {
        z-index: 12 !important;
    }
}

.basic-single {
    /*max-height: 45px;*/
    z-index: initial !important;
    
    .customReactSelectMenu {
        background-color: #fff;
        z-index: 11 !important;

        margin-top: 6px;
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        
        background-color:hsl(0,0%,100%);
        border-radius:4px;
        box-shadow:0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1);

        .btn {
            color: $primary-color;
            margin-top: 0px;
            background-color: transparent;
            text-align: left;
            font-size: 12px;
            max-width: 100%;
        }
    }
    
    .select__control, .select__indicators {
        z-index: initial !important;
    }

    .select__menu-list {
        background-color: #fff;
        z-index: 11 !important;
    }

    .select__option {
        background-color: #fff;
        z-index: 11 !important;
    }

    .select__option--is-selected {
        background-color: $primary-color;
    }

    .customReactSelectMenu + .customReactSelectMenu {
        z-index: 12 !important;

    }
}
