// Card

.card {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
}
.card-header {
  padding: 15px 25px;
  border-radius: 5px;
  margin-top: 0px;
}

.card-body {

  label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
  }

  .form-group {
    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;

    }
  }
}

.slim-card-body {
  padding: 0px 15px 15px 15px;
  border-radius: 5px ;

  .form-group {
    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      margin-left: 10px;
    }

    .custom-select {
      margin-bottom: 10px;
    }
  }

}
.card-footer{
  padding: 0px 15px 15px 15px;
  border: 0px;

}

.card-footer .btn:nth-child(1) {
  margin-right: 15px;
}

.dashboard-wrapper > .card > .card-body {
  padding: 0px;
}


