// Dashboard toolbar
.c-dashboard-toolbar {
  position: fixed;
  top: 60px;
  right: 0px;
  width: 0px;
  z-index: 99999;
  background: #fff;
}

.dashboard-widget .card {
  background-color: white;
}

.react-grid-item .c-dashboard-toolbar {
  position: absolute;
  top: 0px;
  right: 0px;
}

.c-dashboard-toolbar button {
  display: none;
}

.c-dashboard-toolbar::before {
  content:"\A";
  border-style: solid;
  border-width: 5px 5px 5px 0;
  border-color: transparent #000 transparent transparent;
  position: absolute;
  left: -15px;
  top: 14px;
}

.c-dashboard-toolbar .card {
  margin: 0px;
}

.c-dashboard-toolbar:hover/*, .c-dashboard-toolbar.show*/ {
  width: auto;
  border: 1px solid #000;
  border-radius: 0px;
}

.c-dashboard-toolbar:hover button/*, .c-dashboard-toolbar.show button*/ {
  display: block;
}

.c-dashboard-toolbar:hover::before/*, .c-dashboard-toolbar.show::before*/ {
  content:"";
  display: none;
}

//  Floating debug
.floating-debug {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255,255,255,0.5);
  border: 1px solid rgba(0,0,0,0.5);
  max-height: 40vh;
  overflow-y: auto;
}

//  Floating messages
.floating-messages {
  position: fixed;
  top: 70px;
  right: 15px;
  z-index: 9000;
}

//  Page loading
.page-loading {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 20px;
  background-color: $white2-color;
  padding: 120px;
}

// .actions-wrapper {
//   border: 1px solid #E9E9E9;
//   border-radius: 0px;
//   padding: 5px;
// }

// .actions-entry {
//   margin: 4px;
//   border: 1px solid #ccc;
//   padding: 10px;
//   background-color: #fff;
//   min-width: 180px;
// }

// .action-entry-delete {
//   line-height: 8px;
// }

// .dashboard-widget .card {
//   background-color: #fff;

// }

// .btn-primary {
//   color: #000;
//   background-color: $primary-color;
// }

// .btn-primary:hover,
// .btn-primary:focus,
// .btn-primary.focus,
// .btn-primary:not(:disabled):not(.disabled):active,
// .show > .btn-primary.dropdown-toggle {
//   color: #000;
//   background-color: $primary-color-hover;
//   //border-radius: 5px;
// }

#fieldLabels, #fieldOptionsMapping, #columnWidthMapping, #defaultGroups {


}

.ace_editor {
  width: 100% !important;
}